<template>
  <b-modal
    id="modal-user"
    ref="refModal"
    title="người dùng"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Tên người dùng <span class="text-danger" />
              </template>
              <b-form-input
                id="name"
                v-model="itemLocal.name"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Số điện thoại <span class="text-danger" />
              </template>
              <b-form-input
                id="phone"
                v-model="itemLocal.phone"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label>
                Email <span class="text-danger" />
              </template>
              <b-form-input
                id="email"
                v-model="itemLocal.email"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row>
          <b-col cols="12" class="my-1">
            <div class="d-flex justify-space-between">
              <div
                class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
              >
                1. Hạn mức sử dụng
              </div>
            </div>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="endDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.expiredIn"
                required
                :default-value="itemLocal.expiredIn"
                label="Hạn sử dụng"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- Active/Inactive -->
          <b-col cols="12" class="mt-2">
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.roles[0].limitByTransaction"
                name="check-button"
                switch
                inline
              >
                {{
                  itemLocal.roles[0].limitByTransaction
                    ? "Giới hạn số hóa đơn gạch nợ"
                    : "Không giới hạn số hóa đơn gạch nợ"
                }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- Active/Inactive -->
          <b-col
            cols="12"
            class="mt-2"
            v-if="itemLocal.roles[0].limitByTransaction"
          >
            <b-form-group>
              <template v-slot:label> Số hóa đơn gạch nợ còn lại </template>
              <b-form-input
                id="tingeeTransactionCount"
                v-model="itemLocal.roles[0].tingeeTransactionCount"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="my-1">
            <div class="d-flex justify-space-between">
              <div
                class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
              >
                2. Tài khoản gạch nợ TINGEE
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <template v-slot:label> Client ID </template>
              <b-form-input
                id="tingeeUsername"
                v-model="itemLocal.userConfiguration.tingeeUsername"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <template v-slot:label> Secret Token </template>
              <b-form-input
                id="tingeePassword"
                v-model="itemLocal.userConfiguration.tingeePassword"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label> Webhook URL </template>
              <b-form-input
                id="webhookUrl"
                v-model="webhookUrl"
                placeholder=""
                trim
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="my-1">
            <div class="d-flex justify-space-between">
              <div
                class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
              >
                3. Tài khoản Vicare
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label> SĐT đăng nhập </template>
              <b-form-input
                id="newZaloPhone"
                v-model="itemLocal.userConfiguration.newZaloPhone"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label> Mật khẩu </template>
              <b-form-input
                id="newZaloPassword"
                v-model="itemLocal.userConfiguration.newZaloPassword"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <template v-slot:label> Mã tài khoản Zalo </template>
              <b-form-input
                id="newZaloSenderId"
                v-model="itemLocal.userConfiguration.newZaloSenderId"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SelectDate from "@/views/components/SelectDate.vue";
import useUserModal from "./useUserModal";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,

    SelectDate,
    BFormCheckbox,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      maxSlots,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
      webhookUrl,
    } = useUserModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      maxSlots,
      resetItemLocal,
      resetModal,
      onSubmit,
      webhookUrl,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
./useUserModal
